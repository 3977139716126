import React from 'react'
import * as HomepageContactFormStyle from './css/homepageContactForm.module.css'
const HomepageContactForm = () => (
  <>
    <div className={`container-fluid home ${HomepageContactFormStyle.home}`}>
      <div className='container '>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 offset-xl-3 offset-lg-3'>
            <h2 className='text-center'>Kontakta oss</h2>
          </div>

          <div className={`col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 offset-xl-3 offset-lg-3 ${HomepageContactFormStyle.form}`}>
            <form name='Kontakta oss formulär från hemsidan' method='POST' data-netlify='true' action='/info/tack'>
              <input type='hidden' name='form-name' value='Kontakta oss formulär från hemsidan' />
              <div className='form-group'>
                <label htmlFor='exampleFormControlInput1'>E-postadress</label>
                <input type='email' name='E-postadress' className='form-control' id='exampleFormControlInput1' placeholder='namn@exempel.se' required />
              </div>
              <div className='form-group'>
                <label htmlFor='exampleFormControlInput1'>Telefon</label>
                <input type='tel' name='Telefon' className='form-control' id='exampleFormControlInput1' placeholder='Telefon' required />
              </div>
              <div className='form-group'>
                <label htmlFor='exampleFormControlTextarea1'>Meddelande</label>
                <textarea className='form-control' type='text' name='Meddelande' id='exampleFormControlTextarea1' rows='3' placeholder='Meddelande' required />
              </div>
              <button type='submit' className='btn btn-danger'>Skicka</button>
            </form>

          </div>

        </div>
      </div>
    </div>
  </>

)
export default HomepageContactForm
