import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import * as OppetiderStyle from '../../components/css/pages/pageOppettiderOchInformation.module.css'
import HomepageContactForm from '../../components/homepageContactForm'
const Kontakt = () => (
  <Layout>
    <Seo title='Öppettider' />
    <PageHeader text='Öppettider och information' />

    <div className='container'>
      <div className='row'>
      {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  text-center'>
            <h1>Under vecka 29 och 30 har trafikskolan semesterstängt</h1>
      </div> */}

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card mb-3 border-0'>
            <div className='card-header text-white themeColorOrginal'>Öppetider</div>
            <div className='card-body'>
              <table className={`table-borderless info ${OppetiderStyle.info}`}>
                <tbody>
                  <tr>
                    <td>Mån - Tor</td>
                    <td>08:30 - 18:00</td>
                  </tr>
                  <tr>
                    <td>Fredag</td>
                    <td>08:30 - 17:00</td>
                  </tr>
                  <tr>
                    <td>Lunch</td>
                    <td>13:30 - 14:00</td>
                  </tr>
                  <tr>
                    <td>Lör - Sön</td>
                    <td>Stängt</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card mb-3 border-0'>
            <div className='card-header text-white themeColorOrginal'>Besöksadress</div>
            <div className='card-body'>
              <table className={`table-borderless info ${OppetiderStyle.info}`}>
                <tbody>
                  <tr>
                    <td>Golfvägen 12</td>
                  </tr>
                  <tr>
                    <td>182 31 Danderyd</td>
                  </tr>
                  <tr>
                    <td>Orgnummer: 556294-3018</td>
                  </tr>
                  <tr>
                    <td>Portkod: 7452 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
          <div className='card mb-3 border-0'>
            <div className='card-header text-white themeColorOrginal'>Kontakt</div>
            <div className='card-body'>
              <table className={`table-borderless info ${OppetiderStyle.info}`}>
                <tbody>
                  <tr>
                    <td>Telefon: </td>
                    <td>08-755 33 63</td>
                  </tr>
                  <tr>
                    <td>E-post:</td>
                    <td>info@danderydstrafikskola.se</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <iframe title='karta' id='map' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2031.1298068066112!2d18.03535302024021!3d59.3975467671386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9c41451d2f61%3A0x9ad2f138a1d40b47!2sDanderyds%20Trafikskola!5e0!3m2!1ssv!2sse!4v1607182690638!5m2!1ssv!2sse' frameBorder='0' allowFullScreen='' aria-hidden='false' />
        </div>
      </div>
    </div>

    <HomepageContactForm />

  </Layout>
)

export default Kontakt
